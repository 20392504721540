export const SUBSCRIPTION_TAB_TYPE = {
    WARNING: 1,
    ERROR: 2,
    REPORT: 3,
    TOUR_KOCO: 4,
    PERFORMANCE_REPORT: 5
};

class SubscriptionTabsType {
    static getLabel(type) {
        switch (type) {
            case SUBSCRIPTION_TAB_TYPE.WARNING:
                return 1;
            case SUBSCRIPTION_TAB_TYPE.ERROR:
                return 2;
            case SUBSCRIPTION_TAB_TYPE.REPORT:
                return 3;
            case SUBSCRIPTION_TAB_TYPE.TOUR_KOCO:
                return 4;
            case SUBSCRIPTION_TAB_TYPE.PERFORMANCE_REPORT:
                return 5;
            default:
                return 1;
        }
    }

    static getTranslation(type) {
        switch (type) {
            case SUBSCRIPTION_TAB_TYPE.WARNING:
                return 'event_info_warning';
            case SUBSCRIPTION_TAB_TYPE.ERROR:
                return 'event_info_error';
            case SUBSCRIPTION_TAB_TYPE.REPORT:
                return 'event_info_report';
            case SUBSCRIPTION_TAB_TYPE.TOUR_KOCO:
                return 'event_info_tour_koco';
            case SUBSCRIPTION_TAB_TYPE.PERFORMANCE_REPORT:
                return 'event_info_tour_performance';
            default:
                return '-';
        }
    }
}

export default SubscriptionTabsType;
