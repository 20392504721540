const devConfig = {
  BASE_URL: 'http://localhost:40085/',
  GET_UNITS: '/api/v1/units',
  POST_BODY_INFOS: '/api/v1/unitsbodyinfos',
  POST_SIGNAL_EVENT_DATA: '/api/v1/unitssignaleventdatas',
  POST_LIVE_DATA: '/api/v1/unitslivedatas',
  POST_PASSIVE_VEHICLE_ACTIVE_ERRORS: '/api/v1/passivevehiclesactiveerrors',
  GET_MARKERS:'/api/v1/units/markers',
  GET_LOAD_DATA :'/fleettracking',
  GET_LIVE_DATA:'/livedatas',
  GET_CONSUMPTION: '/consumption',
  GET_LINES:'/lines',
  GET_SIGNALS: '/signals',
  GET_POSITIONS_ALL: '/positions',
  GET_LINES_CALCULATION : '/process',
  GET_GEO_FENCES: '/api/v1/geofences',
  GET_POSITIONS: '/api/v1/positions',
  GET_AREAS: '/api/v1/areas',
  GET_PARSE_DATA : '/api/v1/parsedatavalues',
  GET_OLD_DATA: '/api/v1/olddata',
  GET_LINE_AREA: '/lineareas',
  POST_MARKERS : '/api/v1/bins/filters',
  USER_PHOTO: "/user-photos",
  VEHICLE_PHOTO: "/vehicle-photos",
  EMAIL_ATTACHMENT: "/email-attachment",

};

const testConfig = {
  BASE_URL: "",
  GET_UNITS: "/api/v1/units",
  POST_BODY_INFOS: "/api/v1/unitsbodyinfos",
  POST_SIGNAL_EVENT_DATA: "/api/v1/unitssignaleventdatas",
  POST_LIVE_DATA: "/api/v1/unitslivedatas",
  POST_PASSIVE_VEHICLE_ACTIVE_ERRORS: "/api/v1/passivevehiclesactiveerrors",
  GET_MARKERS: "/api/v1/units/markers",
  GET_LOAD_DATA: "/fleettracking",
  GET_LIVE_DATA: "/livedatas",
  GET_CONSUMPTION: "/consumption",
  GET_LINES: "/lines",
  GET_SIGNALS: "/signals",
  GET_LINES_CALCULATION: "/process",
  GET_GEO_FENCES: "/api/v1/geofences",
  GET_POSITIONS: "/api/v1/positions",
  GET_AREAS: "/api/v1/areas",
  GET_PARSE_DATA: "/api/v1/parsedatavalues",
  GET_OLD_DATA: "/api/v1/olddata",
  GET_POSITIONS_ALL: "/positions",
  GET_LINE_AREA: "/lineareas",
  USER_PHOTO: "/user-photos",
  VEHICLE_PHOTO: "/vehicle-photos",
  POST_MARKERS : '/api/v1/bins/filters',
  EMAIL_ATTACHMENT: "/email-attachment",


};

const prodConfig = {
  BASE_URL: '',
  GET_UNITS: '/api/v1/units',
  POST_BODY_INFOS: '/api/v1/unitsbodyinfos',
  POST_SIGNAL_EVENT_DATA: '/api/v1/unitssignaleventdatas',
  POST_LIVE_DATA: '/api/v1/unitslivedatas',
  POST_PASSIVE_VEHICLE_ACTIVE_ERRORS: '/api/v1/passivevehiclesactiveerrors',
  GET_MARKERS:'api/v1/units/markers',
  GET_LOAD_DATA :'fleettracking',
  GET_LIVE_DATA:'/livedatas',
  GET_CONSUMPTION: '/consumption',
  GET_LINES:'/lines',
  GET_SIGNALS: '/signals',
  GET_LINES_CALCULATION : '/process',
  GET_GEO_FENCES: '/api/v1/geofences',
  GET_POSITIONS: '/api/v1/positions',
  GET_AREAS: '/api/v1/areas',
  GET_PARSE_DATA : '/api/v1/parsedatavalues',
  GET_OLD_DATA: '/api/v1/olddata',
  GET_POSITIONS_ALL: '/positions',
  GET_LINE_AREA: '/lineareas',
  POST_MARKERS : '/api/v1/bins/filters',
  USER_PHOTO: "/user-photos",
  VEHICLE_PHOTO: "/vehicle-photos",
  EMAIL_ATTACHMENT: "/email-attachment",


};

const apiUrlConfig = process.env.NODE_ENV === 'development' ? devConfig : (process.env.NODE_ENV === 'production' ? prodConfig : testConfig);

export default apiUrlConfig;
