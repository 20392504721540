// Internet Explorer 11 requires polyfills and partially supported by this project.
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import './i18n';
import './styles/app-base.css';
import './styles/app-components.css';
import './styles/app-utilities.css';
import ReactDOM from 'react-dom';
import App from 'app/App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license-pro';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
//LicenseInfo.setLicenseKey('3634a764a58b0e665a4f9a06a5702de5Tz05MzA0OCxFPTE3NTA4NDI1NjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
LicenseInfo.setLicenseKey('3634a764a58b0e665a4f9a06a5702de5Tz05MzA0OCxFPTE3NTA4NDI1NjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

root.render(<App />);

// ReactDOM.render(<App />, document.getElementById('root'));

reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
