import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import FuseUtils from "@fuse/utils";
import AxiosInterceptorUtils from "app/utils/AxiosInterceptorUtils";

export const getReportInfo = createAsyncThunk(
  "main/taReportInfos/taReportInfo/getReportInfo",
  async (id) => {
    const response = await axios.get(`/api/v1/reportinfos/${id}`, {
      headers: { "X-UI-State": "reportInfo.detail" },
    });

    let data = await response.data;
    if (!data || data === undefined) return null;

    return data;
  }
);

export const patchReportInfo = createAsyncThunk(
  "main/taReportInfos/taReportInfo/patchReportInfo",
  async (reportInfo, { getState }) => {
    const { main } = getState();
    console.log("inside patch is ", reportInfo);

    let clone = _.cloneDeep(reportInfo);
    let payload = AxiosInterceptorUtils.preparePayload(
      "patch",
      "reportInfo",
      clone
    );

    const response = await axios.patch(
      `api/v1/reportinfos/${reportInfo.id}`,
      payload
    );

    const data = await response.data;

    return data;
  }
);

export const postReportInfo = createAsyncThunk(
  "main/taReportInfos/taReportInfo/postReportInfo",
  async (reportInfo, { getState }) => {
    const { main } = getState();
    console.log("inside post is ", reportInfo);

    let clone = _.cloneDeep(reportInfo);
    let payload = AxiosInterceptorUtils.preparePayload(
      "post",
      "reportInfo",
      clone
    );

    const response = await axios.post(`api/v1/reportinfos`, payload);
    const data = await response.data;

    return data;
  }
);

// export const getDuplicateCheck = createAsyncThunk(
//   "main/settingsStClassifier/stClassifier/getDuplicateCheck",
//   async (name) => {
//     const response = await axios.get(
//       `/api/v1/stclassifierduplicate?name=${name}
//     `
//     );
//     const data = await response.data;
//     return data === undefined ? null : data;
//   }
// );

const generateNewReportInfo = () => {
  return {
    id: "",
    name: "",
    description: "",
    layoutType: "",
  };
};

const TaReportInfoSlice = createSlice({
  name: "main/taReportInfos/taReportInfo",
  initialState: {
    loadingReportInfo: false,
    loadingReportInfos: false,
    entity: null,
    hasError: false,
    defaultReportInfo: null,
    isDuplicate: false,
    columns: [],
    fixedColumns: [],
    selectedReportInfo: null,
    selectedReportType: null,
  },
  reducers: {
    setHasError: {
      reducer: (state, action) => {
        state.hasError = action.payload;
      },
    },
    newReportInfo: {
      reducer: (state, action) => {
        // state.loadingDepot = false;
        state.entity = generateNewReportInfo();
        state.entity.id = "new";
      },
    },
    setIsDuplicate: {
      reducer: (state, action) => {
        state.isDuplicate = action.payload;
        // state.loadingReportInfo = false;
      },
    },
    resetReportInfo: {
      reducer: (state, action) => {
        state.entity = generateNewReportInfo();
        state.loadingReportInfo = false;
      },
    },
    setColumns: {
      reducer: (state, action) => {
        state.columns = action.payload;
      },
    },
    setSelectedReportInfo: {
      reducer: (state, action) => {
        state.selectedReportInfo = action.payload;
      },
    },
    resetSelectedReportType: {
      reducer: (state, action) => {
        state.selectedReportType = null;
      },
    },
    setSelectedReportType: {
      reducer: (state, action) => {
        state.selectedReportType = action.payload;
      },
    },
    setDefaultReportInfo: {
      reducer: (state, action) => {
        state.defaultReportInfo = action.payload;
      },
    },
    setFixedColumns: {
      reducer: (state, action) => {
        state.fixedColumns = action.payload;
      },
    },
  },
  extraReducers: {
    [getReportInfo.fulfilled](state, { payload }) {
      state.entity = payload;
      state.loadingReportInfo = false;
    },
    [getReportInfo.pending]: (state) => {
      state.loadingReportInfo = true;
    },
    [getReportInfo.rejected]: (state) => {
      state.loadingReportInfo = false;
    },
  },
});

export const {
  setHasError,
  newReportInfo,
  resetReportInfo,
  setIsDuplicate,
  setColumns,
  setSelectedReportInfo,
  setDefaultReportInfo,
  setSelectedReportType,
  resetSelectedReportType,
  setFixedColumns,
} = TaReportInfoSlice.actions;

export default TaReportInfoSlice.reducer;
