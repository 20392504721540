const locale = {
  APPLICATIONS: 'Applications',
  EXAMPLE: 'Example',
  DASHBOARDS: 'Dashboards',
  FLEET_OVERVIEW: 'Flottenübersicht',
  FLEET_TRACKING: 'Fleet Tracking',
  SERVICE_DASHBOARD: 'Service Dashboard',
  TOUR_ANALYSIS: 'Tour-Bericht',
  REPORTS: 'Reports',
  SCANTEC : 'SCANTEC',
  SETTINGS: 'Settings',
  SETTINGS_PROFILE: 'Profile',
  SETTINGS_COMPANIES: 'Company administration',
  SETTINGS_TRUCKS: 'Vehicle administration',
  SETTINGS_MODULES: 'Module administration',
  SETTINGS_ROLES: 'Role administration',
  SETTINGS_USERS: 'User administration',
  SETTINGS_ERROR_REPORTS: 'Error report',
  "settings_profile" : "Mein Konto",
  "menu_settings": "Einstellungen",
  "settings_company_title" : "Firmenverwaltung",
  "settings_vehicle_title" : "Fahrzeugverwaltung",
  "settings_module_title" : "Modulverwaltung",
  "settings_role_title" : "Rollenverwaltung",
  "settings_user_title" : "Benutzerverwaltung",
  "settings_error_info_title" : "Fehlerinfo",
  "settings_vehicle_group_title" : "Fahrzeuggruppenverwaltung",
  "settings_subscription_title" : "Abonnement-Verwaltung",
  "settings_event_info_title" : "Veranstaltungsverwaltung",
  "scantec" : "SCANTEC",
  "scantec_settings" : "Verwaltung", 
  "scantec_settings_tourplannig" : "Touren-Planung",
  "scantec_settings_tour" : "Touren-Stammdaten",
  "scantec_tour_overview" : "Tourenübersicht",
  "scantec_tour_map" : "Kartenübersicht",
  "scantec_settings_tourcollector" : "Tourensammler",
  "scantec_analysis" : "Analyse",
  "scantec_quality_distribution" : "Qualitätsverteilung",
  "scantec_tour_details": "Tourdetails",
  "documentation" : "Dokumentation",
  "scantec_settings_region": "Gebiete",
  "scantec_settings_tour_details" : "Tourdetails bearbeiten",
  "settings_depot_title": "Depotverwaltung",
  "ecotec_fleet": "ECOTEC FLEET",
  "smartscan_classifier": "Klassifikator Verwaltung",
  "settings_error_code_group_title": "Fehlergruppen Verwaltung",
  "tour_Analyse_2.0": "Tour Analyse",
  "touranalyse_overview": "Übersicht",
  "touranalyse_details": "Adressliste",
  "touranalyse_map": "Karte",
  "touranalyse_settings": "Verwaltung",
  "touranalyse_settings_tour": "Tour",
  "touranalyse_settings_plannig": "Touren-Planung",
  "touranalyse_settings_collector": "Tourensammler",
  "report_infos_title": "Performance Reports",
  "touranalyse-report-info": "Berichtsvorlagen",
  "settings_email_administration_title" : "E-Mail Verwaltung"
};

export default locale;
