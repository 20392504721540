import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';
import axios from 'axios';

export const getReports = createAsyncThunk('main/performanceReports/reports/getReports', async (searchParams) => {
  const response = await axios.get(`api/v1/performancereports`, { params: searchParams, headers: {'X-UI-State': 'settings.reportInfo.list'} });
  const data = await response.data;
  return {reports: data.performance_reports, totalcount: data.performance_reports?.length};
});

export const getReportInfos = createAsyncThunk('main/performanceReports/reports/getReportInfos', async (type) => {
  const response = await axios.get(`api/v1/reportinfos`, { params: { type: type }, headers: { 'X-UI-State': 'perfReports.detail' } });

  let data = await response.data;
  if (!data || typeof data?.reportinfos === 'undefined')
    return [];

  return {
    reportInfos: data.reportinfos
  };
});

export const getUnits = createAsyncThunk('main/performanceReports/reports/getUnits', async () => {
  const response = await axios.get(`api/v1/units`, { params: { limit: 0 }, headers: {'X-UI-State': 'user.settings.trucks'} });
  const data = await response.data;

  return data.units;
});

export const getStTours = createAsyncThunk('main/performanceReports/reports/getStTours', async () => {
  const response = await axios.get(`api/v1/sttour`, { params: { limit: 0 }, headers: { 'X-UI-State': 'stTourOverview' } });
  const data = await response.data;

  return data.st_tours;
});

export const patchReportInfo = createAsyncThunk('main/performanceReports/report/patchReportInfo', async (reportInfo, { getState }) => {
  const { main } = getState();

  let clone = _.cloneDeep(reportInfo);
  let payload = AxiosInterceptorUtils.preparePayload('patch', 'reportInfo', clone);
  console.log('reportInfo to patch', payload);

  const response = await axios.patch(`api/v1/reportinfos/${reportInfo.id}`, payload);

  const data = await response.data;

  return data;
});

export const postReportInfo = createAsyncThunk('main/performanceReports/report/postReportInfo', async (reportInfo, { getState }) => {
  const { main } = getState();
  console.log('post ', reportInfo);

  let clone = _.cloneDeep(reportInfo);
  let payload = AxiosInterceptorUtils.preparePayload('post', 'reportInfo', clone);

  const response = await axios.post(`api/v1/reportinfos`, payload);
  const data = await response.data;

  return data;
});

const reportsAdapter = createEntityAdapter({});

export const { selectAll: selectReports, selectById: selectReportById } =
  reportsAdapter.getSelectors((state) => state.main.performanceReports.reports.reports);

const unitsAdapter = createEntityAdapter({});

export const { selectAll: selectUnits, selectById: selectUnitById } =
  unitsAdapter.getSelectors((state) => state.main.performanceReports.reports.units);

const stToursAdapter = createEntityAdapter({});

export const { selectAll: selectStTours, selectById: selectStTourById } =
  stToursAdapter.getSelectors((state) => state.main.performanceReports.reports.stTours);

const reportInfosAdapter = createEntityAdapter({ selectId: (model) => model.id });

export const { selectAll: selectReportInfos, selectById: selectReportInfoById } =
  reportInfosAdapter.getSelectors((state) => state.main.performanceReports.reports.reportInfos);

const reportsSlice = createSlice({
  name: 'main/performanceReports/reports',
  initialState: {
    searchText: '',
    page: 0,
    pageSize: 10,
    sortModel: [{ field: 'dateTime', sort: 'desc' }],
    rangeDate: [null, null],
    selectedUnit: null,
    loadingReports: false,
    reportInfoCount: 0,
    reports: reportsAdapter.getInitialState({ }),
    loadingUnits: false,
    units: unitsAdapter.getInitialState({ }),
    selectedReportType: '',
    stTours: stToursAdapter.getInitialState({}),
    selectedTour: null, 
    reportInfos: reportInfosAdapter.getInitialState({ }),
    selectedReportInfo: null,
    defaultReportInfo: null,
    columns: [],
    fixedColumns: [],
    fitToContent: false,
    savingReportInfo: false,
  },
  reducers: {
    setReportsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    resetReportInfoFilters: {
      reducer: (state, action) => {
        stTourViewFiltersAdapter.removeAll(state.stTourViewFilters);
      },
    },
    setPage: {
      reducer: (state, action) => {
        state.page = action.payload;
      },
    },
    setPageSize: {
      reducer: (state, action) => {
        state.pageSize = action.payload;
      },
    },
    setSortModel: {
      reducer: (state, action) => {
        state.sortModel = action.payload;
      },
    },
    setRangeDate: {
      reducer: (state, action) => {
        state.rangeDate = action.payload;
      },
    },
    setSelectedUnit: {
      reducer: (state, action) => {
        state.selectedUnit = action.payload;
      },
    },
    setSelectedReportType: {
      reducer: (state, action) => {
        state.selectedReportType = action.payload;
      },
    },
    setSelectedTour: {
      reducer: (state, action) => {
        state.selectedTour = action.payload;
      },
    },
    setSelectedReportInfo: {
      reducer: (state, action) => {
        state.selectedReportInfo = action.payload;
      },
    },
    setDefaultReportInfo: {
      reducer: (state, action) => {
        state.defaultReportInfo = action.payload;
      },
    },    
    setColumns: {
      reducer: (state, action) => {
        state.columns = action.payload;
      },
    },
    setFixedColumns: {
      reducer: (state, action) => {
        state.fixedColumns = action.payload;
      },
    },
    clearReportInfos: {
      reducer: (state, action) => {
        reportInfosAdapter.removeAll(state.reportInfos);
      },
    },
    clearReports: {
      reducer: (state, action) => {
        reportsAdapter.removeAll(state.reports);
      },
    },
    setFitToContent: {
      reducer: (state, action) => {
        state.fitToContent = action.payload;
      },
    },

    
  },
  extraReducers: {
    [getReports.fulfilled](state, { payload }) {
      reportsAdapter.setAll(state.reports, payload.reports);
      state.reportInfoCount = payload.totalcount;
      state.loadingReports = false;
    },
    [getReports.pending]: (state) => {
      state.loadingReports = true;
      state.reportInfoCount = 0;
      reportsAdapter.removeAll(state.reports);
    },
    [getReports.rejected]: (state) => {
      state.loadingReports = false;
    },

    [getReportInfos.fulfilled](state, { payload }) {
      reportInfosAdapter.setAll(state.reportInfos, payload.reportInfos);
      state.reportInfoCount = payload.totalcount;
      state.loadingReportInfos = false;
    },
    [getReportInfos.pending]: (state) => {
      state.loadingReportInfos = true;
      state.reportInfoCount = 0;
      reportInfosAdapter.removeAll(state.reportInfos);
    },
    [getReportInfos.rejected]: (state) => {
      state.loadingReportInfos = false;
    },

    [getUnits.fulfilled](state, { payload }) {
      unitsAdapter.setAll(state.units, payload);
      state.loadingUnits = false;
    },
    [getUnits.pending]: (state) => {
      state.loadingUnits = true;
      unitsAdapter.removeAll(state.units);
    },
    [getUnits.rejected]: (state) => {
      state.loadingUnits = false;
    },

    [getStTours.fulfilled](state, { payload }) {
      stToursAdapter.setAll(state.stTours, payload);
      state.loadingStTours = false;
    },
    [getStTours.pending]: (state) => {
      state.loadingStTours = true;
      stToursAdapter.removeAll(state.stTours);
    },
    [getStTours.rejected]: (state) => {
      state.loadingStTours = false;
    },

    [patchReportInfo.fulfilled](state, { payload }) {
      state.savingReportInfo = false;
    },
    [patchReportInfo.pending](state, { payload }) {
      state.savingReportInfo = true;
    },
    [patchReportInfo.rejected](state, { payload }) {
      state.savingReportInfo = false;
    },

    [postReportInfo.fulfilled](state, { payload }) {
      state.savingReportInfo = false;
    },
    [postReportInfo.pending](state, { payload }) {
      state.savingReportInfo = true;
    },
    [postReportInfo.rejected](state, { payload }) {
      state.savingReportInfo = false;
    },

  },
});

export const { 
  setReportsSearchText,
  resetReportInfoFilters,
  setPage,
  setPageSize,
  setSortModel,
  setRangeDate,
  setSelectedUnit,
  setSelectedReportType,
  setSelectedTour,
  setSelectedReportInfo,
  setDefaultReportInfo,
  setColumns,
  setFixedColumns,
  clearReportInfos,
  setFitToContent,
  clearReports,

} = reportsSlice.actions;

export default reportsSlice.reducer;