export const ST_BIN_EVENT_RESULT = {
    GREEN: 0,
    YELLOW: 1,
    RED: 2,
};

export class StBinEventResultType {

    static label = {
        0: 'GREEN',
        1: 'YELLOW',
        2: 'RED',
        '-1': 'Not Evaluated'
    };

    static translationKey = {
        0: 'st_bin_event_result_green',
        1: 'st_bin_event_result_yellow',
        2: 'st_bin_event_result_red',
        '-1': 'st_bin_event_result_not_evaluated',
    };
}

export default StBinEventResultType;
