import { SUBSCRIPTION_TAB_TYPE } from "app/types/SubscriptionTabsType";

class AxiosInterceptorUtils {
  static preparePayload = function (verb, route, clone) {
    switch (verb) {
      case "post":
      case "patch":
        console.log(route);
        switch (route) {
          case "parameterrequests":
            delete clone.id;
            delete clone.isExpanded;
            delete clone.commandGatewayStatus;
            delete clone.changeSource;
            delete clone.createdAt;
            delete clone.updatedAt;
            delete clone.user;
            delete clone.isDeleted;
            delete clone.visibleIndex;
            delete clone.parentId;
            delete clone.secondaryRequests;
            delete clone.loadingChanges;
            delete clone.allowAddDetail;
            delete clone.parameterVehicleValues;

            if (clone.hasOwnProperty("parameterRequestDetails")) {
              _.forEach(clone.parameterRequestDetails, function (detail) {
                delete detail.isExpanded;
                delete detail.oldValue;
                delete detail.parameter;
                delete detail.isDuplicatedNumber;
                delete detail.id;
                delete detail.validationErrors;
              });
            }
            return {
              parameter_request: clone,
            };

          case "inspection":
            delete clone.id;
            delete clone.showType;

            return {
              inspection: clone,
            };

          case "company":
            delete clone.id;

            return {
              company: clone,
            };

          case "user":
            delete clone.id;
            delete clone.lastLogin;
            delete clone.userType;
            delete clone.isEnabled;
            delete clone.profilePicture;
            // delete clone.password;
            delete clone.expireTime;
            delete clone.isConfirmed;
            delete clone.createUserToken;
            if (clone.company) clone.company = clone.company.id;
            clone.username = clone.email;
            // delete clone.depot;
            if (clone.depot) clone.depot = clone.depot.id;
            delete clone.appVersion;
            delete clone.lastLogin;
            delete clone.companyAccesses;
            if (clone.roleKocos && clone.roleKocos.length > 0) {
              clone.roleKocos = _.map(clone.roleKocos, "id");
            }
            delete clone.passwordConfirm;
            if (clone.password) {
              clone.plainPassword = {
                first: clone.password,
                second: clone.password,
              };
            }
            delete clone.password;

            return {
              user: clone,
            };

          case "depot":
            delete clone.id;

            return {
              depot: clone,
            };

          case "stClassifier":
            delete clone.id;
            delete clone.isDeleted;
            delete clone.createdAt;
            delete clone.company;
            if (clone.isPublic) {
              clone.parent = null;
              // clone.company = null;
              clone.stAiModel = clone.stAiModel.id;
            }
            if (!clone.isPublic) {
              clone.stAiModel = null;
              clone.parent = clone.parent.id;
            }

            return {
              stClassifier: clone,
            };

          case "role":
            if (clone.company) clone.company = clone.company.id;

            delete clone.id;
            delete clone.archived;
            delete clone.accessRights;
            if (clone.access) {
              clone.access = clone.access.map((role) => ({
                module: role - (role % 100),
                accessRight: parseInt(role),
              }));
            }

            return {
              role_koco: clone,
            };

          case "passivevehicle":
            delete clone.id;
            delete clone.nameKoco;
            delete clone.isRcv;
            delete clone.showOnMap;
            delete clone.archived;
            delete clone.currentVersion;
            // delete clone.extra;
            delete clone.extra.mileageStatistics;
            delete clone.properties;
            
            // delete clone.companyAccesses
            if (clone.companyAccesses) {
              clone.companyAccesses = clone.companyAccesses.map(
                  (access) => access.id
              );
            }
            delete clone.driveTypeGroup;
            delete clone.isStillRunning;
            delete clone.isActivToday;
            delete clone.chassisTypeKoco;
            delete clone.truckPicture;
            delete clone.statusUpdate;
            delete clone.payload;
            delete clone.firstInstallation;
            delete clone.bodyRemainWorkingHours;
            delete clone.bodyValueFullStand;
            delete clone.bodyValueFullPump;
            delete clone.lifterRemainWorkingHours;
            delete clone.chassisAdBlueLevel;
            delete clone.chassisDiesel;
            delete clone.engineCoolantTempWarning;
            delete clone.engineCoolantTemp;
            delete clone.depotPosition;
            delete clone.engineOilPressureWarning;
            delete clone.engineOilPressure;
            delete clone.leftFootstepActive;
            delete clone.rightFootstepActive;
            delete clone.updateDate;
            delete clone.isNewRcv;
            delete clone.activeErrorsCount;
            delete clone.optimizationCompaction;
            delete clone.optimizationCompactionDate;
            delete clone.driveTypeGroup;
            delete clone.lastHandoverDepotName;
            delete clone.isAvailable;
            // if (clone.companyAccesses) {
            //   clone.companyAccesses = clone.companyAccesses.map(
            //       (access) => access.id
            //   );
            // }
            delete clone.driveTypeGroup;
            delete clone.isStillRunning;
            delete clone.isActivToday;
            delete clone.chassisTypeKoco;
            delete clone.truckPicture;
            delete clone.statusUpdate;
            delete clone.payload;
            delete clone.firstInstallation;
            delete clone.bodyRemainWorkingHours;
            delete clone.bodyValueFullStand;
            delete clone.bodyValueFullPump;
            delete clone.lifterRemainWorkingHours;
            delete clone.chassisAdBlueLevel;
            delete clone.chassisDiesel;
            delete clone.engineCoolantTempWarning;
            delete clone.engineCoolantTemp;
            delete clone.depotPosition;
            delete clone.engineOilPressureWarning;
            delete clone.engineOilPressure;
            delete clone.leftFootstepActive;
            delete clone.rightFootstepActive;
            delete clone.updateDate;
            delete clone.isNewRcv;
            delete clone.activeErrorsCount;
            delete clone.optimizationCompaction;
            delete clone.optimizationCompactionDate;
            delete clone.driveTypeGroup;
            delete clone.lastHandoverDepotName;
            delete clone.isAvailable;


            delete clone.id;

            return {
              passive_vehicle: clone,
            };

          case "passivevehiclegroup":
            delete clone.id;
            delete clone.isSelected;
            delete clone.isPublic;
            delete clone.archived;
            delete clone.createdBy;

            if (clone.company) clone.company = clone.company.id;

            return {
              passive_vehicle_group: clone,
            };

          case "subscription":
            //console.log("clone fromm subscription", clone);

            delete clone.id;
            delete clone.isSelected;
            delete clone.archived;
            delete clone.createdBy;
            delete clone.numberOfUsers;
            delete clone.numberOfVehicles;
            delete clone.numberOfEvents;
            delete clone.code;
            delete clone.selectedReportType;

/*             console.log("clone fromm subscription", clone);
            console.log("clone fromm errorTabSubscriptions", clone.errorTabSubscriptions); */
            
            if (clone.subType != SUBSCRIPTION_TAB_TYPE.WARNING) delete clone.warningSubscriptions;
            if (clone.subType != SUBSCRIPTION_TAB_TYPE.ERROR) delete clone.errorTabSubscriptions;
            if (clone.subType != SUBSCRIPTION_TAB_TYPE.REPORT) delete clone.reportSubscriptions;
            // if (clone.subType == 4) {
            //   clone.eventSubscriptions = [];
            //   clone.errorSubscriptions = [];
            // }
            // if (clone.subType != 4) {
            //   clone.tourSubscription = {
            //     type: 0,
            //     sendSMS: false,
            //     sendEmail: false,
            //   };
            if (clone.subType == SUBSCRIPTION_TAB_TYPE.PERFORMANCE_REPORT) {
              clone.eventSubscriptions = [];
              clone.errorSubscriptions = [];
              clone.perfReportSubscription.cycle= clone.perfReportSubscription.cycle;
              clone.perfReportSubscription.report = clone.perfReportSubscription.report.id;
            }
            if (clone.subType != SUBSCRIPTION_TAB_TYPE.PERFORMANCE_REPORT) delete clone.perfReportSubscription;
            if (
              clone.warningSubscriptions ||
              clone.errorTabSubscriptions ||
              clone.reportSubscriptions
            )
              clone.eventSubscriptions = [];
            else if (clone.eventSubscriptions)
              clone.eventSubscriptions = clone.eventSubscriptions.map(
                (ei) => ({
                  eventInfo: ei.eventInfo ? ei.eventInfo : ei.id,
                  sendSMS: ei.sendSMS,
                  sendEmail: ei.sendEmail,
                })
              );
            else if (clone.errorSubscriptions)
              clone.errorSubscriptions = clone.errorSubscriptions.map(
                (ei) => ({
                  errorgroup: ei.errorGroup ? ei.errorGroup.id : ei.id,
                  isResolved: ei.isResolved,
                })
              );
            // }

            // if (clone.tourSubscriptions) {
            //   clone.tourSubscriptions = clone.tourSubscriptions;
            // }

            //if (clone.subType) clone.subType = clone.subType.id;
            if (clone.subType && clone.subType.id) clone.subType = clone.subType.id;

            if (clone.company) clone.company = clone.company.id;

            if (clone.users) clone.users = clone.users.map((user) => user.id);

            if (clone.passiveVehicles)
              clone.passiveVehicles = clone.passiveVehicles.map((p) => p.id);

            if (clone.passiveVehicleGroups)
              clone.passiveVehicleGroups = clone.passiveVehicleGroups.map(
                (p) => p.id
              );

            if (clone.warningSubscriptions)
              clone.eventSubscriptions = clone.eventSubscriptions.concat(
                clone.warningSubscriptions.map((ei) => ({
                  eventInfo: ei.eventInfo ? ei.eventInfo : ei.id,
                  sendSMS: ei.sendSMS,
                  sendEmail: ei.sendEmail,
                }))
              );

            if (clone.errorTabSubscriptions)
              clone.errorSubscriptions = clone.errorSubscriptions.concat(
                clone.errorTabSubscriptions.map((ei) => ({
                  errorgroup: ei.errorGroup ? ei.errorGroup.id : ei.id,
                  isResolved: ei.isResolved,
                }))
              );

            if (clone.reportSubscriptions)
              clone.eventSubscriptions = clone.eventSubscriptions.concat(
                clone.reportSubscriptions.map((ei) => ({
                  eventInfo: ei.id,
                  sendSMS: ei.sendSMS,
                  sendEmail: ei.sendEmail,
                }))
              );

            delete clone.warningSubscriptions;
            delete clone.errorTabSubscriptions;
            delete clone.reportSubscriptions;

            return {
              subscription: clone,
            };

          case "sttourplan":
            // delete clone.id;
            delete clone.isNew;
            delete clone.archived;
            delete clone.updated;
            delete clone.deleted;
            delete clone.fromTime;
            delete clone.toTime;

            if(typeof clone.tourPlanDeviceType?.id !== 'undefined'){
              clone.vehicleDeviceType = clone.tourPlanDeviceType.id;
              clone.tourPlanDeviceType = clone.tourPlanDeviceType.id;

            } else {
              clone.vehicleDeviceType = clone.tourPlanDeviceType;
            }

            if (clone.vehicle) clone.vehicle = clone.vehicle.id;

            if (clone.stTour) clone.stTour = clone.stTour.id;

            if (clone.stClassifier) clone.stClassifier = clone.stClassifier.id;

            return {
              st_tour_plan: clone,
            };

          case "tourplan":
            // delete clone.id;
            delete clone.isNew;
            delete clone.archived;
            delete clone.updated;
            delete clone.deleted;
            delete clone.fromTime;
            delete clone.toTime;

            if (clone.vehicle) clone.vehicle = clone.vehicle.id;

            if (clone.stTour) clone.stTour = clone.stTour.id;

            if (clone.classifierType) clone.classifierType = clone.classifierType.id;

            return {
              tour_plan: clone,
            };

          case "sttours":
            // delete clone.id;
            delete clone.isNew;
            delete clone.date;
            delete clone.savedDate;
            delete clone.archived;
            return {
              st_tour: clone,
            };

          case "sttouroverview":
            // delete clone.id;
            delete clone.isNew;
            delete clone.archived;
            delete clone.updated;
            delete clone.deleted;
            delete clone.fromTime;
            delete clone.toTime;
            delete clone.vehicle;
            delete clone.firstLastEmptyingDate;

            if (clone.stTour) clone.stTour = clone.stTour.id;

            return {
              st_tour_overview: clone,
            };
            break;

          case "touroverview":
            // delete clone.id;
            delete clone.isNew;
            delete clone.archived;
            delete clone.updated;
            delete clone.deleted;
            delete clone.fromTime;
            delete clone.toTime;
            delete clone.vehicle;
            delete clone.firstLastEmptyingDate;

            if (clone.stTour) clone.stTour = clone.stTour.id;

            return {
              tour_overview: clone,
            };

          case "stdeepscansettingsrequest":
            delete clone.id;
            delete clone.isExpanded;
            delete clone.updatedAt;
            delete clone.createdAt;
            delete clone.machineType;
            delete clone.commandGatewayStatus;
            delete clone.isDeleted;
            delete clone.user;

            clone.stDeepScanSettingsRequestDetails = { ...clone.details };
            delete clone.details;

            if (clone.vehicle) clone.vehicle = clone.vehicle.id;

            return {
              st_deep_scan_settings_request: clone,
            };

          case "stRegion":
            delete clone.id;
            delete clone.isSelected;
            delete clone.archived;
            delete clone.createdBy;
            delete clone.company;

            if (clone.type) clone.type = clone.type.id;
            if (clone.stTours)
              clone.stTours = clone.stTours.map((stTour) => stTour.id);

            return {
              st_region: clone,
            };

            if (clone.vehicle) clone.vehicle = clone.vehicle.id;

            return {
              st_deep_scan_settings_request: clone,
            };

          case "reportInfo":
            delete clone.id;
            delete clone.archived;
            delete clone.createdBy;
            delete clone.updatedBy;
            delete clone.layoutType;
            delete clone.updatedAt;
            delete clone.createdAt;
            delete clone.reportType;
            delete clone.groupInfos;

            clone.columnInfos = clone.columnInfos?.map(colInfo => ({
              column: colInfo.column,
              index: colInfo.index,
              selectable:colInfo.selectable
            }));
            return {
              report_info: clone,
            };
        }
        break;
    }
  };
}

export default AxiosInterceptorUtils;
