import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import AxiosInterceptorUtils from "app/utils/AxiosInterceptorUtils";
import axios from "axios";

export const getReportInfos = createAsyncThunk(
  "main/performanceReports/reports/getReportInfos",
  async (searchParams) => {
    
    const response = await axios.get(`api/v1/reportinfos`, { 
      params: 
      { 
        offset: searchParams.offset,
        limit: searchParams.limit,
        sort: searchParams.sort,
        type: searchParams.type,
        order: searchParams.order,
        name: searchParams.name
       }, headers: { "X-UI-State": "reportInfo.detail" },
    });

    let data = await response.data;
    if (!data || typeof data?.reportinfos === "undefined") return [];

    return {
      reportInfos: data.reportinfos,
    };
  }
);

const reportInfosAdapter = createEntityAdapter({
  selectId: (model) => model.id,
});

export const {
  selectAll: selectReportInfos,
  selectById: selectReportInfoById,
} = reportInfosAdapter.getSelectors(
  (state) => state.main.taReportInfos.taReportInfos.reportInfos
);

const TaReportInfosSlice = createSlice({
  name: "main/taReportInfos/taReportInfos",
  initialState: {
    searchText: "",
    page: 0,
    pageSize: 10,
    sortModel: [{ field: "name", sort: "desc" }],
    rowsPerPage: 10,
    rangeDate: [null, null],
    selectedClassifiers: null,
    name: null,
    loadingReportInfos: false,
    reportInfos: reportInfosAdapter.getInitialState([]),
    reportInfosCount: 0,
  },
  reducers: {
    setSettingsReportInfosSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },

    resetReportInfos: {
      reducer: (state, action) => {
        reportInfosAdapter.setAll(state.reportInfos, "");
        state.loadingReportInfos = false;
      },
    },
    setPage: {
      reducer: (state, action) => {
        state.page = action.payload;
      },
    },
    setPageSize: {
      reducer: (state, action) => {
        state.pageSize = action.payload;
      },
    },
    setSortModel: {
      reducer: (state, action) => {
        state.sortModel = action.payload;
      },
    },

    setSelectedClassifiers: {
      reducer: (state, action) => {
        state.selectedClassifiers = action.payload;
      },
    },
    setRowsPerPage: {
      reducer: (state, action) => {
        state.rowsPerPage = action.payload;
      },
    },
    setName: {
      reducer: (state, action) => {
        state.name = action.payload;
      },
    },
  },

  extraReducers: {
    [getReportInfos.fulfilled](state, { payload }) {
      reportInfosAdapter.setAll(state.reportInfos, payload.reportInfos);
      state.reportInfoCount = payload.reportInfos.length;
      state.loadingReportInfos = false;
    },
    [getReportInfos.pending]: (state) => {
      state.loadingReportInfos = true;
      state.reportInfosCount = 0;
      reportInfosAdapter.removeAll(state.reportInfos);
    },
    [getReportInfos.rejected]: (state) => {
      state.loadingReportInfos = false;
    },
  },
});

export const {
  setsettingsReportInfosSearchText,
  setPage,
  setPageSize,
  setSortModel,
  setRangeDate,
  setSelectedUnit,
  setSelectedClassifiers,
  setRowsPerPage,
  resetReportInfos,
  setName,
} = TaReportInfosSlice.actions;

export default TaReportInfosSlice.reducer;
