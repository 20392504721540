import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import FuseUtils from "@fuse/utils";
import AxiosInterceptorUtils from "app/utils/AxiosInterceptorUtils";
import getChassisManufacturer from "app/types/chassisManufacturerType";
import apiUrlConfig from "app/apiUrlConfig";
import { EVENT_INFO_TYPE } from "app/types/EventInfoType";

export const getSubscription = createAsyncThunk(
  "main/settingsSubscription/subscription/getSubscription",
  async (id) => {
    const response = await axios.get(`api/v1/subscriptions/${id}`, {
      headers: { "X-UI-State": "settings.subscription.detail" },
    });
    let data = await response.data;

    if (data === undefined) return null;

    if (data && data.passiveVehicles)
      data.passiveVehicles = data.passiveVehicles.map((vehicle) => ({
        id: vehicle.id,
        isGroup: false,
      }));

    if (data && data.passiveVehicleGroups) {
      if (!data.passiveVehicles) data.passiveVehicles = [];

      data.passiveVehicles = data.passiveVehicles.concat(
        data.passiveVehicleGroups.map((group) => ({
          id: group.id,
          isGroup: true,
        }))
      );
    }

    return data;
  }
);

function transformErrorSubscriptions(errorSubscriptions) {
  return errorSubscriptions.map(error => ({
    errorgroup: error.id,
    isResolved: error.isResolved
  }));
}

function transformEventSubscriptions(eventSubscriptions) {
  return eventSubscriptions.map(error => ({
    eventInfo: error.eventInfo?.id ? error.eventInfo.id : error.eventInfo,
    sendSMS: error.sendSMS,
    sendEmail: error.sendEmail,
  }));
}

export const patchSubscription = createAsyncThunk(
  "main/settingsSubscription/subscription/patchSubscription",
  async (subscription, { getState, rejectWithValue }) => {
    //debugger;
    let clone = _.cloneDeep(subscription);
    let payload = AxiosInterceptorUtils.preparePayload(
      "patch",
      "subscription",
      clone
    );

    if (payload.subscription.subType === 1) {
      delete payload.subscription.errorSubscriptions;
      payload.subscription.eventSubscriptions = transformEventSubscriptions(payload.subscription.eventSubscriptions);
    }

    if (payload.subscription.subType === 2) {
      delete payload.subscription.eventSubscriptions;
      payload.subscription.errorSubscriptions = transformErrorSubscriptions(payload.subscription.errorSubscriptions);
    }

    try {
      const response = await axios.patch(
        `api/v1/subscriptions/${subscription.id}`,
        payload
      );
      const data = await response.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const postSubscription = createAsyncThunk(
  "main/settingsSubscription/subscription/postSubscription",
  async (data, { getState, rejectWithValue }) => {
    let clone = _.cloneDeep(data);
    let payload = AxiosInterceptorUtils.preparePayload(
      "post",
      "subscription",
      clone
    );

    if (payload.subscription.subType === 2) {
      delete payload.subscription.eventSubscriptions;
    }
    if (payload.subscription.subType === 1 || payload.subscription.subType?.id === 1) {
      delete payload.subscription.errorSubscriptions;
    }

    try {
      const response = await axios.post(`api/v1/subscriptions`, payload);
      const data = await response.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUnitsByCompany = createAsyncThunk(
  "main/settingsSubscription/subscription/getUnitsByCompany",
  async (company) => {
    const responseUnits = await axios.get(
      `api/v1/companies/${company.id}/passivevehicles`,
      {
        params: { limit: 0 },
        headers: { "X-UI-State": "kocobox.fleetoverview" },
      }
    );

    const responseGroups = await axios.get(
      `api/v1/companies/${company.id}/passivevehiclegroups`,
      {
        params: { limit: 0 },
        headers: { "X-UI-State": "kocobox.fleetoverview" },
      }
    );

    const dataUnits = await responseUnits.data;
    const dataGroups = await responseGroups.data;

    let result = [];

    // if (dataUnits && dataUnits.passivevehiclecompanyaccesses)
    //   result = dataUnits.passivevehiclecompanyaccesses.map(
    //     (access) => access.passive_vehicle
    //   );
      if (dataUnits && dataUnits.passivevehiclecompanyaccesses) {
        result = dataUnits.passivevehiclecompanyaccesses.map((access) => {
          const passiveVehicle = access?.passive_vehicle ?? null;
          const tourAnalyse2 = access?.tourAnalyse2 ?? null;
      
          if (passiveVehicle) {
            passiveVehicle.tourAnalyse2 = tourAnalyse2;
          }
      
          return passiveVehicle;
        });
      }
          
    if (dataGroups && dataGroups.passivevehiclegroups) {
      let resultGroups = dataGroups.passivevehiclegroups.map((group) => ({
        id: group.id,
        isGroup: true,
        constructionType: group.name,
        passiveVehicles: group.passiveVehicles,
        ktr: "VEHICLE GROUP",
      }));
      result = result.concat(resultGroups);
    }

    return result;
  }
);

// TODO: check headers
export const getUsersByCompany = createAsyncThunk(
  "main/settingsSubscription/subscription/getUsersByCompany",
  async (company) => {
    const response = await axios.get(`api/v1/companies/${company.id}/users`, {
      params: { limit: 0 },
      headers: { "X-UI-State": "kocobox.fleetoverview" },
    });
    const data = await response.data;

    if (!data || !data.users) return [];

    return data.users;
  }
);

// TODO: check headers
export const getPassiveVehicleGroupsByCompany = createAsyncThunk(
  "main/settingsSubscription/subscription/getPassiveVehicleGroupsByCompany",
  async (company) => {
    const response = await axios.get(
      `api/v1/companies/${company.id}/passivevehiclegroups`,
      { params: { limit: 0 }, headers: { "X-UI-State": "settings.list" } }
    );
    const data = await response.data;

    if (!data || !data.passivevehiclegroups) return [];

    return data.passivevehiclegroups;
  }
);

export const getBodyInfos = createAsyncThunk(
  "main/settingsSubscription/subscription/getBodyInfos",
  async (ids) => {
    const response = await axios.post(apiUrlConfig.POST_BODY_INFOS, {
      type: 9,
      ids: ids,
    });
    const data = await response.data;

    return data.value.map((val) => {
      return {
        id: val.vehicle,
        changes: {
          chassisType: val.chassisType,
          chassisTypeString: getChassisManufacturer(val.chassisType),
        },
      };
    });
  }
);

export const activateSubscription = createAsyncThunk(
  "main/settingsSubscription/subscription/activateSubscription",
  async (subscription, { getState }) => {
    const { main } = getState();

    const response = await axios.patch(
      `api/v1/subscriptions/${subscription.id}/enable`
    );
    const data = await response.data;

    return data;
  }
);

export const deleteSubscription = createAsyncThunk(
  "main/settingsSubscription/subscription/deleteSubscription",
  async (subscription, { getState }) => {
    const { main } = getState();

    const response = await axios.delete(
      `api/v1/subscriptions/${subscription.id}`
    );
    const data = await response.data;

    return data;
  }
);

export const getCompanies = createAsyncThunk(
  "main/settingsSubscription/subscription/getCompanies",
  async () => {
    const response = await axios.get(`api/v1/companies`, {
      params: { limit: 0 },
      headers: { "X-UI-State": "user.settings.companies" },
    });
    const data = await response.data;

    return data.companies;
  }
);

// TODO, check the headers
export const getEventInfos = createAsyncThunk(
  "main/settingsSubscription/subscription/getEventInfos",
  async (type) => {
    const response = await axios.get(`api/v1/eventinfos/${type}`, {
      params: { limit: 0 },
      headers: { "X-UI-State": "user.settings.subscriptions" },
    });
    let data = await response.data;

    if (data === undefined) return null;

    return data.eventinfos;
  }
);

export const patchSubscriptionPublic = createAsyncThunk(
  "main/settingsSubscription/subscription/patchSubscriptionPublic",
  async (subscription, { getState }) => {
    const { main } = getState();

    const response = await axios.patch(
      `api/v1/subscriptions/${subscription.id}/public`
    );
    const data = await response.data;

    return data;
  }
);

export const patchSubscriptionPrivate = createAsyncThunk(
  "main/settingsSubscription/subscription/patchSubscriptionPrivate",
  async (subscription, { getState }) => {
    const { main } = getState();

    const response = await axios.patch(
      `api/v1/subscriptions/${subscription.id}/private`
    );
    const data = await response.data;

    return data;
  }
);

export const getErrorGroups = createAsyncThunk('main/settingsErrorGroup/errorGroups/getErrorGroups', async (uniqueConstructionTypes) => {
  const response = await axios.get(`api/v1/errorgroupsforeventsubs`, { params: { limit: 0, bodyType : uniqueConstructionTypes} });
  const data = response.data;
  return data.errorgroup;
});

const CompaniesAdapter = createEntityAdapter({});

export const { selectAll: selectCompanies, selectById: selectCompanyById } =
  CompaniesAdapter.getSelectors(
    (state) => state.main.settingsSubscription.subscription.companies
  );

const unitsAdapter = createEntityAdapter({});

export const {
  selectAll: selectUnits,
  selectById: selectUnitById,
  selectIds: selectUnitIds,
} = unitsAdapter.getSelectors(
  (state) => state.main.settingsSubscription.subscription.units
);

const usersAdapter = createEntityAdapter({});

export const {
  selectAll: selectUsers,
  selectById: selectUserById,
  selectIds: selectUserIds,
} = usersAdapter.getSelectors(
  (state) => state.main.settingsSubscription.subscription.users
);

const passiveVehicleGroupsAdapter = createEntityAdapter({});

export const {
  selectAll: selectPassiveVehicleGroups,
  selectById: selectPassiveVehicleGroupById,
  selectIds: selectPassiveVehicleGroupIds,
} = passiveVehicleGroupsAdapter.getSelectors(
  (state) => state.main.settingsSubscription.subscription.passiveVehicleGroups
);

const warningsAdapter = createEntityAdapter({});

export const { selectAll: selectWarnings, selectById: selectWarningById } =
  warningsAdapter.getSelectors(
    (state) => state.main.settingsSubscription.subscription.warnings
  );

export const selectTourSubscription = (state) =>
  state.main.settingsSubscription.subscription.tourSubscription;

// const tourSubscriptionAdapter = createEntityAdapter({});

// export const { tourSubscription } = tourSubscriptionAdapter.getSelectors(
//   (state) => state.main.settingsSubscription.subscription.tourSubscription
// );

const errorsAdapter = createEntityAdapter({});

export const { selectAll: selectErrors, selectById: selectErrorById } =
  errorsAdapter.getSelectors(
    (state) => state.main.settingsSubscription.subscription.errors
  );

const reportsAdapter = createEntityAdapter({});

export const { selectAll: selectReports, selectById: selectReportById } =
  reportsAdapter.getSelectors(
    (state) => state.main.settingsSubscription.subscription.reports
  );

const generateNewSubscription = () => {
  return {
    id: "new",
    company: "",
    name: "",
    description: "",
    passiveVehicles: [],
    passiveVehicleGroups: [],
    users: [],
    selectedReportType: '',
    eventSubscriptions: [],
    errorSubscriptions: [],
    tourSubscription: {},
    perfReportSubscription: {},
    subType: 0,
  };
};

const resetState = (state) => {
  state.entity = null;
  state.loadingSubscription = false;
  state.tabValue = 0;
  unitsAdapter.removeAll(state.units);
  usersAdapter.removeAll(state.users);
  passiveVehicleGroupsAdapter.removeAll(state.passiveVehicleGroups);
  CompaniesAdapter.removeAll(state.companies);
  warningsAdapter.removeAll(state.warnings);
  errorsAdapter.removeAll(state.errors);
  reportsAdapter.removeAll(state.reports);
  state.tourSubscription = {};
  state.perfReportSubscription = {};
  state.loadingCompanies = false;
  state.savingSubscription = false;
  state.unitsSearchText = "";
  state.warningsSearchText = "";
  state.selectedReportType = "",
  state.errorsSearchText = "";
  state.reportsSearchText = "";
  state.loadingUnits = false;
  state.loadingUsers = false;
  state.loadingPassiveVehicleGroups = false;
  state.error = null;
  state.loadingBodyInfos = false;
  state.isWarningsTabSet = false;
  state.isErrorsTabSet = false;
  state.isTourTabSet = false;
  state.isReportsTabSet = false;
  state.subTab = 0;
};

const subscriptionSlice = createSlice({
  name: "main/settingsSubscription/subscription",
  initialState: {
    tourTokoSubscriptions: false,
    unitsSearchText: "",
    warningsSearchText: "",
    errorsSearchText: "",
    reportsSearchText: "",
    loadingSubscription: false,
    selectedReportType: "",
    tabValue: 0,
    entity: null,
    hasTourAnalyse: false,
    loadingUnits: false,
    loadingUsers: false,
    loadingPassiveVehicleGroups: false,
    savingSubscription: false,
    units: unitsAdapter.getInitialState({}),
    users: usersAdapter.getInitialState({}),
    passiveVehicleGroups: passiveVehicleGroupsAdapter.getInitialState({}),
    tourSubscription: {},
    perfReportSubscription: {},
    companies: CompaniesAdapter.getInitialState({}),
    warnings: warningsAdapter.getInitialState({}),
    errors: errorsAdapter.getInitialState({}),
    reports: reportsAdapter.getInitialState({}),
    loadingCompanies: false,
    error: null,
    loadingBodyInfos: false,
    isWarningsTabSet: false,
    isErrorsTabSet: false,
    isTourTabSet: false,
    isReportsTabSet: false,
    subTab: 0,
    loadingErrorGroups: false,
    isDataDirty: false,
    selectedErrorslist: false,
    selectedWarningslist: false,
    selectedPerfReport: false,
    selectedBodyType: [],
    userSelectedGroupForSubscription: [],
    resolvedStatus: {},
    selectedErrorIds: []
  },
  reducers: {
    setUnitsSearchText: {
      reducer: (state, action) => {
        state.unitsSearchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    setIsDataDirty: {
      reducer: (state, action) => {
        state.isDataDirty = action.payload;
      },
    },
    setWarningsSearchText: {
      reducer: (state, action) => {
        state.warningsSearchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    setErrorsSearchText: {
      reducer: (state, action) => {
        state.errorsSearchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    setIsTourChanged: {
      reducer: (state, action) => {
        state.tourTokoSubscriptions = true;
      },
    },
    setReportsSearchText: {
      reducer: (state, action) => {
        state.reportsSearchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    setSelectedReportType: {
      reducer: (state, action) => {
        state.selectedReportType = action.payload;
      },
    },
    setTabValue: {
      reducer: (state, action) => {
        state.tabValue = action.payload;
      },
    },
    setUnits: {
      reducer: (state, action) => {
        unitsAdapter.setAll(state.units, action.payload);
      },
    },
    setHasTourAnalyse: {
      reducer: (state, action) => {
        state.hasTourAnalyse = action.payload;      
      },
    },
    setWarnings: {
      reducer: (state, action) => {
        warningsAdapter.setAll(state.warnings, action.payload);
      },
    },
    // setTourSubscription: {
    //   reducer: (state, action) => {
    //     warningsAdapter.setAll(state.warnings, action.payload);
    //   },
    // },
    setTourSubscription: {
      reducer: (state, action) => {
        state.tourSubscription = action.payload;
      },
    },
    setErrors: {
      reducer: (state, action) => {
        errorsAdapter.setAll(state.errors, action.payload);
      },
    },
    setReports: {
      reducer: (state, action) => {
        reportsAdapter.setAll(state.reports, action.payload);
      },
    },
    resetSubscription: {
      reducer: (state, action) => {
        resetState(state);
        state.isDataDirty = false;
      },
    },
    resetEventSubscription: {
      reducer: (state, action) => {
        // state.warnings = warnings;
      },
    },
    newSubscription: {
      reducer: (state, action) => {
        resetState(state);
        state.entity = generateNewSubscription();
      },
    },
    setIsWarningsTabSet: {
      reducer: (state, action) => {
        state.isWarningsTabSet = action.payload;
      },
    },
    setIsErrorsTabSet: {
      reducer: (state, action) => {
        state.isErrorsTabSet = action.payload;
      },
    },
    setIsTourTabSet: {
      reducer: (state, action) => {
        state.isTourTabSet = action.payload;
      },
    },
    setIsReportsTabSet: {
      reducer: (state, action) => {
        state.isReportsTabSet = action.payload;
      },
    },
    setSelectedErrorslist: {
      reducer: (state, action) => {
        state.selectedErrorslist = action.payload;
      }
    },
    setSelectedWarningslist: {
      reducer: (state, action) => {
        state.selectedWarningslist = action.payload;
      }
    },
    setSelectedPerfReport: {
      reducer: (state, action) => {
        state.selectedPerfReport = action.payload;
      }
    },
    setSelectedBodyType: {
      reducer: (state, action) => {
        state.selectedBodyType = action.payload;
      },
    },
    setUserSelectedGroupForSubscription: {
      reducer: (state, action) => {
        state.userSelectedGroupForSubscription = action.payload;
      },
    },
    setResolvedStatus: (state, action) => {
      state.resolvedStatus = { ...state.resolvedStatus, ...action.payload };
    },
    setSelectedErrorIds: (state, action) => {
      state.selectedErrorIds = action.payload;
    },
  },
  extraReducers: {
    [getSubscription.fulfilled](state, { payload }) {
      state.entity = payload;
      state.loadingSubscription = false;
    },
    [getSubscription.pending]: (state) => {
      resetState(state);
      state.loadingSubscription = true;
    },
    [getSubscription.rejected]: (state) => {
      state.loadingSubscription = false;
    },

    [patchSubscription.fulfilled](state, { payload }) {
      state.savingSubscription = false;
    },
    [patchSubscription.pending](state, { payload }) {
      state.savingSubscription = true;
    },
    [patchSubscription.rejected](state, { payload }) {
      state.error = payload;
      state.savingSubscription = false;
    },

    [postSubscription.fulfilled](state, { payload }) {
      state.savingSubscription = false;
    },
    [postSubscription.pending](state, { payload }) {
      state.savingSubscription = true;
    },
    [postSubscription.rejected](state, { payload }) {
      state.error = payload;
      state.savingSubscription = false;
    },

    [getUnitsByCompany.fulfilled](state, { payload }) {
      unitsAdapter.setAll(state.units, payload);
      state.loadingUnits = false;
    },
    [getUnitsByCompany.pending]: (state) => {
      state.loadingUnits = true;
      unitsAdapter.removeAll(state.units);
    },
    [getUnitsByCompany.rejected]: (state) => {
      state.loadingUnits = false;
    },

    [getUsersByCompany.fulfilled](state, { payload }) {
      usersAdapter.setAll(state.users, payload);
      state.loadingUsers = false;
    },
    [getUsersByCompany.pending]: (state) => {
      state.loadingUsers = true;
      usersAdapter.removeAll(state.users);
    },
    [getUsersByCompany.rejected]: (state) => {
      state.loadingUsers = false;
    },

    [getPassiveVehicleGroupsByCompany.fulfilled](state, { payload }) {
      passiveVehicleGroupsAdapter.setAll(state.passiveVehicleGroups, payload);
      state.loadingPassiveVehicleGroups = false;
    },
    [getPassiveVehicleGroupsByCompany.pending]: (state) => {
      state.loadingPassiveVehicleGroups = true;
      passiveVehicleGroupsAdapter.removeAll(state.passiveVehicleGroups);
    },
    [getPassiveVehicleGroupsByCompany.rejected]: (state) => {
      state.loadingPassiveVehicleGroups = false;
    },

    [activateSubscription.fulfilled](state, { payload }) {
      state.savingSubscription = false;
    },
    [activateSubscription.pending](state, { payload }) {
      state.savingSubscription = true;
    },
    [activateSubscription.rejected](state, { payload }) {
      state.savingSubscription = false;
    },

    [deleteSubscription.fulfilled](state, { payload }) {
      state.savingSubscription = false;
    },
    [deleteSubscription.pending](state, { payload }) {
      state.savingSubscription = true;
    },
    [deleteSubscription.rejected](state, { payload }) {
      state.savingSubscription = false;
    },

    [getCompanies.fulfilled](state, { payload }) {
      CompaniesAdapter.setAll(state.companies, payload);
      state.loadingCompanies = false;
    },
    [getCompanies.pending]: (state) => {
      state.loadingCompanies = true;
      CompaniesAdapter.removeAll(state.companies);
    },
    [getCompanies.rejected]: (state) => {
      state.loadingCompanies = false;
    },

    [getEventInfos.fulfilled](state, { payload }) {
      const warnings = payload.filter(
        (eventInfo) => eventInfo.type == EVENT_INFO_TYPE.WARNING
      );
      warningsAdapter.setAll(state.warnings, warnings);
      const errors = payload.filter(
        (eventInfo) => eventInfo.type == EVENT_INFO_TYPE.ERROR
      );
      errorsAdapter.setAll(state.errors, errors);
      const reports = payload.filter(
        (eventInfo) => eventInfo.type == EVENT_INFO_TYPE.REPORT
      );
      reportsAdapter.setAll(state.reports, reports);
      state.loadingEventInfos = false;
    },
    [getEventInfos.pending]: (state) => {
      state.loadingEventInfos = true;
      warningsAdapter.removeAll(state.warnings);
      errorsAdapter.removeAll(state.errors);
      reportsAdapter.removeAll(state.reports);
    },
    [getEventInfos.rejected]: (state) => {
      state.loadingEventInfos = false;
    },

    [patchSubscriptionPublic.fulfilled](state, { payload }) {
      state.savingSubscription = false;
    },
    [patchSubscriptionPublic.pending](state, { payload }) {
      state.savingSubscription = true;
    },
    [patchSubscriptionPublic.rejected](state, { payload }) {
      state.savingSubscription = false;
    },

    [patchSubscriptionPrivate.fulfilled](state, { payload }) {
      state.savingSubscription = false;
    },
    [patchSubscriptionPrivate.pending](state, { payload }) {
      state.savingSubscription = true;
    },
    [patchSubscriptionPrivate.rejected](state, { payload }) {
      state.savingSubscription = false;
    },

    [getBodyInfos.pending](state, { payload }) {
      state.loadingBodyInfos = true;
    },
    [getBodyInfos.rejected](state, { payload }) {
      state.loadingBodyInfos = false;
    },
    [getBodyInfos.fulfilled](state, { payload }) {
      unitsAdapter.updateMany(state.units, payload);
      state.loadingBodyInfos = false;
    },
    [getErrorGroups.fulfilled](state, { payload }) {
      if (payload) {
        const mergedData = payload.map((errorGroup) => {
          const isSelected = state.userSelectedGroupForSubscription.some(selected => selected.id === errorGroup.id);
          const isResolved = state.resolvedStatus[errorGroup.id] || false;
          return { ...errorGroup, isSelected, isResolved};
        });
        errorsAdapter.setAll(state.errors, mergedData);
      } else {
        //console.error('Payload is undefined or null:', payload);
      }
      state.loadingErrorGroups = false;
    },
    [getErrorGroups.pending]: (state) => {
      state.loadingErrorGroups = true;
      errorsAdapter.removeAll(state.errors);
      state.loadingErrorGroups = false;
    },
    [getErrorGroups.rejected]: (state) => {
      state.loadingErrorGroups = false;
    },
  },
});

export const {
  setTabValue,
  resetSubscription,
  resetEventSubscription,
  newSubscription,
  setUnits,
  setUnitsSearchText,
  setWarningsSearchText,
  setErrorsSearchText,
  setReportsSearchText,
  setWarnings,
  setErrors,
  setReports,
  setIsWarningsTabSet,
  setIsErrorsTabSet,
  setIsTourTabSet,
  setIsReportsTabSet,
  setIsTourChanged,
  setTourSubscription,
  setIsDataDirty,
  setSelectedErrorslist,
  setSelectedWarningslist,
  setSelectedPerfReport,
  setSelectedBodyType,
  setUserSelectedGroupForSubscription,
  setResolvedStatus,
  setHasTourAnalyse,
  setSelectedReportType
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;