import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getEmail = createAsyncThunk('main/settingsEmail/email/getEmail', async (id) => {
    const response = await axios.get(`api/v1/emails/${id}`, { headers: { 'X-UI-State': 'settings.email.detail' } });
    let data = await response.data;

    if (data === undefined)
        return null;
    return data;
});

export const getCompanies = createAsyncThunk('main/settingsEmail/email/getCompanies', async () => {
    const response = await axios.get(`api/v1/companies`, { params: { limit: 0 }, headers: { 'X-UI-State': 'user.settings.companies', } });
    const data = await response.data;

    return data.companies;
});

export const getUsersByCompany = createAsyncThunk('main/settingsEmail/email/getUsersByCompany', async (companyIds) => {
    const response = await axios.post(`api/v1/userlists`, {
        params:
        {
            companies: companyIds,
        }, headers: { "X-UI-State": "kocobox.fleetoverview" }
    });

    const data = await response.data;
    if (!data || !data.users) return [];
    return data.users;
});

export const postEmail = createAsyncThunk('main/settingsEmail/email/postEmail', async (emailBody) => {
    console.log("emailBody", emailBody);
    const response = await axios.post(`api/v1/emails`, emailBody);

    const data = await response.data;
    if (!data || !data.emails) return [];
    return data.emails;
});

const CompaniesAdapter = createEntityAdapter({});

export const { selectAll: selectCompanies, selectById: selectCompanyById } =
    CompaniesAdapter.getSelectors((state) => state.main.settingsEmail.email.companies);

const usersAdapter = createEntityAdapter({});

export const {
    selectAll: selectUsers,
    selectById: selectUserById,
    selectIds: selectUserIds,
} = usersAdapter.getSelectors(
    (state) => state.main.settingsEmail.email.users
);

const emailsAdapter = createEntityAdapter({});

export const {
    selectAll: selectEmails,
    selectById: selectEmailsById,
    selectIds: selectEmailsIds,
} = emailsAdapter.getSelectors(
    (state) => state.main.settingsEmail.email.emails
);

export const patchEmail = createAsyncThunk('main/settingsEmail/email/patchEmail', async (emailBody) => {
    const dataToPatch = {
        email: {
            subject: emailBody.subject,
            companies: emailBody.companies.map(companies => companies.id),
            users: emailBody.users.map(user => user.id),
            emailBody: emailBody.emailBody,
        }
    };
    const response = await axios.patch(`api/v1/emails/${emailBody.id}`, dataToPatch);

    const data = await response.data;
    if (!data || !data.emails) return [];
    return data.emails;
});

const generateNewEmail = () => {
    return {
        id: 'new',
        subject: '',
        companies: [],
        users: [],
        emailBody: ''
    };
};

const resetState = (state) => {
    state.entity = null;
    state.loadingEmail = false;
    state.tabValue = 0;
    state.savingEmail = false;
    state.unitsSearchText = '';
    state.loadingCompany = false;
    state.loadingUsers = false;
    state.loadingEmails = false;
    usersAdapter.removeAll(state.users);
    emailsAdapter.removeAll(state.emails);
    CompaniesAdapter.removeAll(state.companies);
};

const emailSlice = createSlice({
    name: 'main/settingsEmail/email',
    initialState: {
        load: false,
        loadingEmail: false,
        tabValue: 0,
        entity: null,
        load: false,
        savingEmail: false,
        isDataDirty: false,
        loadingEmailResult: false,
        result: { status: false },
        companies: CompaniesAdapter.getInitialState({}),
        loadingUsers: false,
        loadingEmails: false,
        users: usersAdapter.getInitialState({}),
        emails: emailsAdapter.getInitialState({}),
        fileUpload: null,
        dataToForward: {},
        mode: '',

    },
    reducers: {
        setFileUpload: {
            reducer: (state, action) => {
                state.fileUpload = action.payload;
            },
        },
        setSubject: {
            reducer: (state, action) => {
                state.subject = action.payload;
            },
        },
        setCompany: {
            reducer: (state, action) => {
                state.companies = action.payload;
            },
        },
        setUsers: {
            reducer: (state, action) => {
                state.users = action.payload;
            },
        },
        setEmailBody: {
            reducer: (state, action) => {
                state.emailBody = action.payload;
            },
        },
        setIsDataDirty: {
            reducer: (state, action) => {
                state.isDataDirty = action.payload;
            },
        },
        setTabValue: {
            reducer: (state, action) => {
                state.tabValue = action.payload;
            },
        },
        resetEmail: {
            reducer: (state, action) => {
                resetState(state);
                state.isDataDirty = false;
            },
        },
        newEmail: {
            reducer: (state, action) => {
                resetState(state);
                state.entity = generateNewEmail();
            },
        },
        setDataToForward: {
            reducer: (state, action) => {
                state.dataToForward = action.payload;
            },
        },
        setMode: {
            reducer: (state, action) => {
                state.mode = action.payload;
            },
        },
    },
    extraReducers: {
        [getUsersByCompany.fulfilled](state, { payload }) {
            usersAdapter.setAll(state.users, payload);
            state.loadingUsers = false;
        },
        [getUsersByCompany.pending]: (state) => {
            state.loadingUsers = true;
            usersAdapter.removeAll(state.users);
        },
        [getUsersByCompany.rejected]: (state) => {
            state.loadingUsers = false;
        },
        [postEmail.fulfilled](state, { payload }) {
            console.log("Payload received:", payload);
            emailsAdapter.setAll(state.emails, payload);
            state.loadingEmails = false;
            state.savingEmail = false;
        },
        [postEmail.pending]: (state) => {
            state.loadingEmails = true;
            state.savingEmail = true;
            emailsAdapter.removeAll(state.emails);
        },
        [postEmail.rejected]: (state) => {
            state.savingEmail = false;
            state.loadingEmails = false;
        },
        [patchEmail.fulfilled](state, { payload }) {
            state.savingEmail = false;
        },
        [patchEmail.pending]: (state) => {
            state.savingEmail = true;
        },
        [patchEmail.rejected]: (state) => {
            state.savingEmail = false;
        },
        [getCompanies.fulfilled](state, { payload }) {
            CompaniesAdapter.setAll(state.companies, payload);
            state.load = false;
            state.loadingCompanies = false;
        },
        [getCompanies.pending]: (state) => {
            state.load = true;
            state.loadingCompanies = true;
            CompaniesAdapter.removeAll(state.companies);
            state.loadingCompanies = false;
        },
        [getCompanies.rejected]: (state) => {
            state.load = false;
            state.loadingCompanies = false;
        },
        [getEmail.fulfilled](state, { payload }) {
            state.entity = payload;
            state.loadingEmail = false;
        },
        [getEmail.pending]: (state) => {
            resetState(state);
            state.loadingEmail = true;
        },
        [getEmail.rejected]: (state) => {
            state.loadingEmail = false;
        },
    },
});

export const { setTabValue, resetEmail, newEmail, setFileUpload, setSubject, setCompany, setUsers, setEmailBody, setIsDataDirty, setDataToForward, setMode} = emailSlice.actions;
export default emailSlice.reducer;
