import { authRoles } from "app/auth";
import { lazy } from "react";

const TaReportsInfo = lazy(() => import("./TaReportsInfo/TaReportsInfo"));
const TaReportInfo = lazy(() => import("./TaReportInfo/TaReportInfo"));

const TaReportInfoConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "tareports",
      element: <TaReportsInfo />,
      auth: [authRoles.ROLE_REPORT_INFO_READ],
    },
    {
      path: "tareports/:tareportId/*",
      element: <TaReportInfo />,
      auth: [authRoles.ROLE_REPORT_INFO_READ],
    },
  ],
};

export default TaReportInfoConfig;
