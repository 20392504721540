import { lazy } from 'react';
const Email = lazy(() => import('./Email/Email'));
const Emails = lazy(() => import('./Emails/Emails'));

const SettingsEmailConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'emails',
      element: <Emails />,
    },
    {
      path: 'emails/:emailId/*',
      element: <Email />,
    },
  ],
};

export default SettingsEmailConfig;