import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';
import axios from 'axios';

export const getTaBinEvents = createAsyncThunk('main/taTourDetails/getTaBinEvents', async (searchParams) => {
  const response = await axios.post(`api/v1/stbinevents`, {
    params:
    {
      offset: searchParams.offset,
      limit: searchParams.limit,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      unit: searchParams.unit,
      binResult: searchParams.binResult.toString(),
      rfid: searchParams.rfid,
      tour: searchParams.tour,
      sort: searchParams.sort,
      order: searchParams.order,

    }, headers: { 'X-UI-State': 'stTourDetails' }
  });

  let data = await response.data;
  console.log('st Bin Events is ', data.st_bin_events);
  return { stBinEvents: data.st_bin_events, totalcount: data.totalcount };
});

export const getTaBinEventApiIngs = createAsyncThunk('main/taTourDetails/getTaBinEventApiIngs', async (searchParams) => {
  const response = await axios.post(`api/v1/tabinevents`, {
    params:
    {
      offset: searchParams.offset,
      limit: searchParams.limit,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      unit: searchParams.unit,
      binResult: searchParams.binResult.toString(),
      rfid: searchParams.rfid,
      address: searchParams.address,
      tour: searchParams.tour,
      sort: searchParams.sort,
      order: searchParams.order,

    }, headers: { 'X-UI-State': 'taTourDetails' }
  });

  let data = await response.data;
  return { stBinEvents: data.st_bin_events || [], stApiIngs: data.st_api_ings || [], totalcount: data.totalcount };
});

export const getTaApiIngs = createAsyncThunk('main/taTourDetails/getTaApiIngs', async (searchParams) => {
  const response = await axios.post(`api/v1/stapiings`, {
    params:
    {
      offset: searchParams.offset,
      limit: searchParams.limit,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      unit: searchParams.unit,
      binResult: searchParams.binResult?.toString(),
      rfid: searchParams.rfid,
      tour: searchParams.tour,
      sort: searchParams.sort,
      order: searchParams.order

    }, headers: { 'X-UI-State': 'stTourDetails' }
  });

  let data = await response.data;
  return { stApiIngs: data.stapiings, totalcount: data.totalcount };
});

export const getTaBinEventsExport = createAsyncThunk('main/taTourDetails/getTaBinEventsExport', async (searchParams) => {

  const response = await axios.post(`api/v1/tabineventsexports`, {
    params:
    {
      offset: 0,
      limit: 0,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      unit: searchParams.unit,
      binResult: searchParams.binResult.toString(),
      rfid: searchParams.rfid,
      tour: searchParams.tour,
      sort: searchParams.sort,
      order: searchParams.order,
      locale: searchParams.locale,

    },
    headers:
    {
      'X-UI-State': 'stTourDetails'
    }
  },
    {
      responseType: "blob"
    });

  let data = await response.data;
  return data;
});

export const getUnits = createAsyncThunk('main/taTourDetails/getUnits', async () => {
  const response = await axios.get(`api/v1/units`, { params: { limit: 0 }, headers: { 'X-UI-State': 'user.settings.trucks' } });
  const data = await response.data;

  return data.units;
});

export const getTaTours = createAsyncThunk('main/taTourDetails/getTaTours', async () => {
  const response = await axios.get(`api/v1/kocotourname`, { params: { limit: 0 }, headers: { 'X-UI-State': 'stTourDetails' } });
  const data = await response.data;

  return data;
});

export const getTaTourViewFilters = createAsyncThunk('main/taTourDetails/getTaTourViewFilters', async (searchParams) => {
  const response = await axios.post(`api/v1/vehicletoursummaries`, {
    params:
    {
      offset: searchParams.offset,
      limit: searchParams.limit,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      units: searchParams.unit,
      tours: searchParams.tour,
      binResult: searchParams.binResult,
      sort: searchParams.sort,
      order: searchParams.order

    }, headers: { 'X-UI-State': 'filter' }
  });
  let data = await response.data;

  return { stAnalysis: data.vehicletoursummaries, totalcount: data.totalcount };
});


const taBinEventAdapter = createEntityAdapter({});

export const { selectAll: selectTaBinEvents, selectById: selectTaBinEventViewById } =
  taBinEventAdapter.getSelectors((state) => state.main.taTourDetails.taBinEvents);

const taApiIngAdapter = createEntityAdapter({});

export const { selectAll: selectTaApiIngs, selectById: selectTaApiIngById } =
  taApiIngAdapter.getSelectors((state) => state.main.taTourDetails.taApiIngs);

const taBinEventExportAdapter = createEntityAdapter({});

export const { selectAll: selectTaBinEventsExport, selectById: selectTaBinEventExportViewById } =
  taBinEventExportAdapter.getSelectors((state) => state.main.taTourDetails.taBinEventsExport);

const taToursAdapter = createEntityAdapter({});

export const { selectAll: selectTaTours, selectById: selectTaTourById } =
  taToursAdapter.getSelectors((state) => state.main.taTourDetails.taTours);

const unitsAdapter = createEntityAdapter({});

export const { selectAll: selectUnits, selectById: selectUnitById } =
  unitsAdapter.getSelectors((state) => state.main.taTourDetails.units);

const taTourViewFiltersAdapter = createEntityAdapter({});

export const { selectAll: selectTaTourViewFilters, selectById: selectTaTourViewFilterById } =
  taTourViewFiltersAdapter.getSelectors((state) => state.main.taTourDetails.taTourViewFilters);

const taTourDetailsSlice = createSlice({
  name: 'main/taTourDetails',
  initialState: {
    searchText: '',
    page: 0,
    pageSize: 10,
    rowsPerPage: [10, 30, 50],
    sortModel: [{ field: 'date', sort: 'desc' }],
    rangeDate: [null, null],
    selectedUnit: [],
    selectedTour: null,
    selectedBinResult: [],
    rfid: null,
    address: null,
    selectedDatePrevValue: [],
    selectedUnitPrevCount: [],
    unitsCombo: [],
    taToursCombo: [],
    rangeDateCombo: [],
    selectedBinResultCombo: [],
    loadingTaBinEvents: false,
    loadingTaApiIngs: false,
    loadingUnits: false,
    loadingTaTours: false,
    taBinEventsCount: 0,
    taApiIngsCount: 0,
    taBinEvents: taBinEventAdapter.getInitialState({}),
    taApiIngs: taApiIngAdapter.getInitialState({}),
    taTours: taToursAdapter.getInitialState({}),
    units: unitsAdapter.getInitialState({}),
    taTourViewFilters: taTourViewFiltersAdapter.getInitialState({}),
    loadingTaTourViewFilters: false,
    taBinEventsExport: taBinEventExportAdapter.getInitialState({}),
    loadingStBinEventsExport: false,
    taBinEventsExportError: null,
    isPreFilterSet: false,
    smartscanView: 'list',
    selectedTab: 0,
    smartscanRowSelectionModel: [],
    userAdjustedDate: 0,
    userAdjustedRFID: 0,
    userAdjustedIsEmptied: 0,
    userAdjustedLifterType: 0,
    userAdjustedVehicle: 0,
    userAdjustedTour: 0,
    userAdjustedAddress: 0,
    order: [],
  },
  reducers: {
    setTaTourDetailsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setPage: {
      reducer: (state, action) => {
        state.page = action.payload;
      },
    },
    setPageSize: {
      reducer: (state, action) => {
        state.pageSize = action.payload;
      },
    },
    setRowsPerPage: {
      reducer: (state, action) => {
        state.rowsPerPage = action.payload;
      },
    },
    setSortModel: {
      reducer: (state, action) => {
        state.sortModel = action.payload;
      },
    },
    setRangeDate: {
      reducer: (state, action) => {
        state.rangeDate = action.payload;
      },
    },
    setSelectedUnit: {
      reducer: (state, action) => {
        state.selectedUnit = action.payload;
      },
    },
    setSelectedTour: {
      reducer: (state, action) => {
        state.selectedTour = action.payload;
      },
    },
    setSelectedBinResult: {
      reducer: (state, action) => {
        state.selectedBinResult = action.payload;
      },
    },
    setRfid: {
      reducer: (state, action) => {
        state.rfid = action.payload;
      },
    },
    setAddress: {
      reducer: (state, action) => {
        state.address = action.payload;
      },
    },
    setSelectedDatePrevValue: {
      reducer: (state, action) => {
        state.selectedDatePrevValue = action.payload;
      },
    },
    setSelectedUnitPrevCount: {
      reducer: (state, action) => {
        state.selectedUnitPrevCount = action.payload;
      },
    },
    setUnitsCombo: {
      reducer: (state, action) => {
        state.unitsCombo = action.payload;
      },
    },
    setTaToursCombo: {
      reducer: (state, action) => {
        state.taToursCombo = action.payload;
      },
    },
    setRangeDateCombo: {
      reducer: (state, action) => {
        state.rangeDateCombo = action.payload;
      },
    },
    setSelectedBinResultCombo: {
      reducer: (state, action) => {
        state.selectedBinResultCombo = action.payload;
      },
    },
    setIsPreFilterSet: {
      reducer: (state, action) => {
        state.isPreFilterSet = action.payload;
      },
    },
    resetTaTourViewFilters: {
      reducer: (state, action) => {
        taTourViewFiltersAdapter.removeAll(state.taTourViewFilters);
      },
    },
    resetTaBinEventsExport: {
      reducer: (state, action) => {
        taBinEventExportAdapter.removeAll(state.taBinEventsExport);
        state.taBinEventsExportError = null;
      },
    },
    resetTaTourDetailsSlice: {
      reducer: (state, action) => {
        taBinEventExportAdapter.removeAll(state.taBinEventsExport);
        state.taBinEventsExportError = null;
        taBinEventAdapter.removeAll(state.taBinEvents);
        state.taBinEventsFile = null;
        state.isPreFilterSet = false;
        taApiIngAdapter.removeAll(state.taApiIngs);
      },
    },
    setSmartscanView: {
      reducer: (state, action) => {
        state.smartscanView = action.payload;
      },
    },
    setSelectedTab: {
      reducer: (state, action) => {
        state.selectedTab = action.payload;
      },
    },
    setSmartscanRowSelectionModel: {
      reducer: (state, action) => {
        state.smartscanRowSelectionModel = action.payload;
      }
    },
    setUserAdjustedDate: {
      reducer: (state, action) => {
        state.userAdjustedDate = action.payload;
      },
      prepare: (width) => ({ payload: width }),
    },
    setUserAdjustedRFID: {
      reducer: (state, action) => {
        state.userAdjustedRFID = action.payload;
      },
      prepare: (width) => ({ payload: width }),
    },
    setUserAdjustedIsEmptied: {
      reducer: (state, action) => {
        state.userAdjustedIsEmptied = action.payload;
      },
      prepare: (width) => ({ payload: width }),
    },
    setUserAdjustedLifterType: {
      reducer: (state, action) => {
        state.userAdjustedLifterType = action.payload;
      },
      prepare: (width) => ({ payload: width }),
    },
    setUserAdjustedVehicle: {
      reducer: (state, action) => {
        state.userAdjustedVehicle = action.payload;
      },
      prepare: (width) => ({ payload: width }),
    },
    setUserAdjustedTour: {
      reducer: (state, action) => {
        state.userAdjustedTour = action.payload;
      },
      prepare: (width) => ({ payload: width }),
    },
    setUserAdjustedAddress: {
      reducer: (state, action) => {
        state.userAdjustedAddress = action.payload;
      },
      prepare: (width) => ({ payload: width }),
    },
    setColumnOrder(state, action) {
      state.order = action.payload;
    },
  },
  extraReducers: {
    [getTaBinEvents.fulfilled](state, { payload }) {
      taBinEventAdapter.setAll(state.taBinEvents, payload.stBinEvents);
      state.taBinEventsCount = payload.totalcount;
      state.loadingTaBinEvents = false;
    },
    [getTaBinEvents.pending]: (state) => {
      state.loadingTaBinEvents = true;
      taBinEventAdapter.removeAll(state.taBinEvents);
    },
    [getTaBinEvents.rejected]: (state) => {
      state.loadingTaBinEvents = false;
    },

    [getTaBinEventApiIngs.fulfilled](state, { payload }) {
      const modifiedStBinEvents = payload.stBinEvents.map(item => ({
        ...item,
        binId: item.binId !== undefined ? item.binId : '-1'
      }));
      taBinEventAdapter.setAll(state.taBinEvents, modifiedStBinEvents);
      taApiIngAdapter.setAll(state.taApiIngs, payload.stApiIngs);
      state.taBinEventsCount = payload.totalcount;
      state.loadingTaBinEvents = false;
    },
    [getTaBinEventApiIngs.pending]: (state) => {
      state.loadingTaBinEvents = true;
      taBinEventAdapter.removeAll(state.taBinEvents);
      taApiIngAdapter.removeAll(state.taApiIngs);
    },
    [getTaBinEventApiIngs.rejected]: (state) => {
      state.loadingTaBinEvents = false;
    },

    [getTaApiIngs.fulfilled](state, { payload }) {
      taApiIngAdapter.setAll(state.taApiIngs, payload.stApiIngs);
      state.taApiIngsCount = payload.totalcount;
      state.loadingTaApiIngs = false;
    },
    [getTaApiIngs.pending]: (state) => {
      state.loadingTaApiIngs = true;
      taApiIngAdapter.removeAll(state.taApiIngs);
    },
    [getTaApiIngs.rejected]: (state) => {
      state.loadingTaApiIngs = false;
    },

    [getTaBinEventsExport.fulfilled](state, { payload }) {
      // taBinEventExportAdapter.setAll(state.stBinEventsExport, payload.stBinEvents);
      // state.stBinEventsExportCount = payload.totalcount;
      state.taBinEventsFile = payload;
      state.loadingTaBinEventsExport = false;
    },
    [getTaBinEventsExport.pending]: (state) => {
      state.loadingTaBinEventsExport = true;
      state.taBinEventsExportCount = 0;
      state.taBinEventsExportError = null;
      state.taBinEventsFile = null;
      // taBinEventExportAdapter.removeAll(state.stBinEventsExport);
    },
    [getTaBinEventsExport.rejected]: (state) => {
      state.taBinEventsExportError = { msg: 'ta_tour_details_report_cannot_be_generated' };
      state.loadingTaBinEventsExport = false;
    },

    [getTaTours.fulfilled](state, { payload }) {
      const transformedData = Object.values(payload)
      .flatMap(item => item.doc.stTourPlanDetails)
      .map(plan => ({
          name: plan.name,
          id: plan._id.$id,
      }));
      taToursAdapter.setAll(state.taTours, transformedData);
      state.loadingTaTours = false;
    },
    [getTaTours.pending]: (state) => {
      state.loadingTaTours = true;
      taToursAdapter.removeAll(state.taTours);
    },
    [getTaTours.rejected]: (state) => {
      state.loadingTaTours = false;
    },

    [getUnits.fulfilled](state, { payload }) {
      const rcvUnits = payload.filter(unit => unit.isRcv === true)
      unitsAdapter.setAll(state.units, rcvUnits);
      state.loadingUnits = false;
    },
    [getUnits.pending]: (state) => {
      state.loadingUnits = true;
      unitsAdapter.removeAll(state.units);
    },
    [getUnits.rejected]: (state) => {
      state.loadingUnits = false;
    },

    [getTaTourViewFilters.fulfilled](state, { payload }) {
      taTourViewFiltersAdapter.setAll(state.taTourViewFilters, payload.stAnalysis);
      state.taTourViewFiltersCount = payload.totalcount;
      state.loadingTaTourViewFilters = false;
    },
    [getTaTourViewFilters.pending]: (state) => {
      state.loadingTaTourViewFilters = true;
      taTourViewFiltersAdapter.removeAll(state.taTourViewFilters);
    },
    [getTaTourViewFilters.rejected]: (state) => {
      state.loadingTaTourViewFilters = false;
    },
  },
});

export const { setTaTourDetailsSearchText,
  setPage,
  setPageSize,
  setRowsPerPage,
  setSortModel,
  setRangeDate,
  setSelectedUnit,
  setSelectedTour,
  setSelectedBinResult,
  resetTaTourViewFilters,
  setRfid,
  setAddress,
  setSelectedDatePrevValue,
  setSelectedUnitPrevCount,
  setUnitsCombo,
  setTaToursCombo,
  setRangeDateCombo,
  setSelectedBinResultCombo,
  setIsPreFilterSet,
  resetTaBinEventsExport,
  resetTaTourDetailsSlice,
  setSmartscanView,
  setSelectedTab,
  setSmartscanRowSelectionModel,
  setUserAdjustedDate,
  setUserAdjustedRFID,
  setUserAdjustedIsEmptied,
  setUserAdjustedLifterType,
  setUserAdjustedVehicle,
  setUserAdjustedTour,
  setUserAdjustedAddress,
  setColumnOrder,
} = taTourDetailsSlice.actions;

export default taTourDetailsSlice.reducer;
