import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getEmails = createAsyncThunk('main/settingsEmail/emails/getEmails', async () => {
  const response = await axios.get(`api/v1/emails`, { params: { limit: 0 } });
  const data = response.data;
  return data.emails;
});

const emailsAdapter = createEntityAdapter({});

export const { selectAll: selectEmails, selectById: selectEmailById } =
  emailsAdapter.getSelectors((state) => state.main.settingsEmail.emails.emails);

const emailsSlice = createSlice({
  name: 'main/settingsEmail/emails',
  initialState: {
    searchText: '',
    loadingEmails: false,
    emails: emailsAdapter.getInitialState({}),
  },
  reducers: {
    setEmailsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getEmails.fulfilled](state, { payload }) {
      if (payload) {
        emailsAdapter.setAll(state.emails, payload);
      } else {
        console.error('Payload is undefined or null:', payload);
      }
      state.loadingEmails = false;
    },
    [getEmails.pending]: (state) => {
      state.loadingEmails = true;
      emailsAdapter.removeAll(state.emails);
      state.loadingEmails = false;
    },
    [getEmails.rejected]: (state) => {
      state.loadingEmails = false;
    },
  },
});

export const { setEmailsSearchText } = emailsSlice.actions;
export default emailsSlice.reducer;