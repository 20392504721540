import { combineReducers } from "@reduxjs/toolkit";
import taReportInfos from "./TaReportInfosSlice";
import taReportInfo from "./TaReportInfoSlice";

const reducer = combineReducers({
  taReportInfos,
  taReportInfo,
});

export default reducer;
